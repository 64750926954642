import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from 'react-i18next'
import SEO from "@components/Seo"
import FooterSns from "@components/blocks/global/FooterSNS"
import Footer from "@components/blocks/global/Footer"
import PageHeader from "@components/blocks/common/PageHeader"
import css from "@css/components/blocks/common/Page.module.styl"
import List from "@components/blocks/linestickers/List"
import PageSectionTitle from "@components/blocks/common/PageSectionTitle"
import OfficalWorks from "@components/blocks/index/OfficialWorks"

export default function Prodcut({data}) {
	const { t } = useTranslation()

	return (
		<React.Fragment>
			<SEO title={'LINE STICKERS'} />
			<main className={css.container} id="main">
				<PageHeader l1="LINE STICKERS" l2={t('linestickers__headerTitle2')} />
				<div className={css.content}>
					<div className={css.prodcut}>
						<section className={css.section}>
                            <List/>
						</section>
                        <section className={css.section}>
							<PageSectionTitle l1={t('index__supportTitleOfficialWorks')} l2={t('index__officialWorksTitle2')} />
							<OfficalWorks contentMode />
						</section>
					</div>
				</div>
			</main>
			<FooterSns />
			<Footer />
		</React.Fragment>
	)
}

export const pageQuery = graphql`
    query {
        banner03 : file(relativePath: { eq: "Anniversary__img__banner-03@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
            } }
        }
        banner03_2 : file(relativePath: { eq: "Anniversary__img__banner-03-2@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
            } }
        }
    }
`