import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image/withIEPolyfill"
import { FluidImage } from "@components/Image"
import {useTranslation} from 'react-i18next'
import { useURL } from '@hooks/useURL'

import Link from "@components/Link"
import LinkWSvg from "@images/Common__icn__link.svg"


import css from "@css/components/blocks/linestickers/List.module.styl"


export default function List({contentMode})
{
    const { t } = useTranslation()
    const q = useStaticQuery(graphql`
        query {
            banner03 : file(relativePath: { eq: "Anniversary__img__banner-03@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                } }
            }
            banner03_2 : file(relativePath: { eq: "Anniversary__img__banner-03-2@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                } }
            }
            banner03_3 : file(relativePath: { eq: "Anniversary__img__linesticker-03@2x.jpg" }) {
                childImageSharp { fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                } }
            }
        }
    `)

    return (
        <div className={css.container}>
            <Link className={css.banners__item__img} to="https://line.me/S/sticker/23252407" target="_blank">
                <FluidImage data={q.banner03_3} alt="ミライ小町 LINEスタンプ発売！全24種類/¥120" />
                <div className={css.banners__item__button}>
                    {t('linestickers__downloadButton')}
                    <span className={css.icon}><LinkWSvg /></span>
                </div>
            </Link>
            <Link className={css.banners__item__img} to="https://store.line.me/stickershop/product/16086092" target="_blank">
                <FluidImage data={q.banner03_2} alt="ミライ小町 様々なシーンで使いやすい！ LINEスタンプ第2弾発売！全32種" />
                <div className={css.banners__item__button}>
                    {t('linestickers__downloadButton')}
                    <span className={css.icon}><LinkWSvg /></span>
                </div>
            </Link>
            <Link className={css.banners__item__img} to="https://line.me/S/sticker/15418986" target="_blank">
                <FluidImage data={q.banner03} alt="ミライ小町 LINEスタンプ発売！全24種類/¥120" />
                <div className={css.banners__item__button}>
                    {t('linestickers__downloadButton')}
                    <span className={css.icon}><LinkWSvg /></span>
                </div>
            </Link>
        </div>
    )
}